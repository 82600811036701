.seller-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px 16px 35px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 100%;
  .seller-name-container {
    display: flex;
    align-items: center;
  }
  .seller-info {
    margin-bottom: 16px;

    .seller-avatar {
      background: #e0f7fa;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #00796b;
      margin: 0 auto 8px;
      text-transform: uppercase;
      margin-right: 10px;
    }
    .seller-body {
      display: grid;
    }
    .seller-name {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 8px;
    }

    .seller-rating {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;

      .stars {
        color: #ffa000;
      }

      .star-half {
        color: #ccc;
      }
    }

    .seller-content {
      font-size: 14px;
      color: #8b96a5;
      margin-bottom: 10px;
      //   opacity: 0.6;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    .seller-location img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  .send-inquiry-btn {
    background: var(--background-color) !important;
    color: var(--text-color) !important;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 8px;
    width: 100%;

    &:hover {
      background: var(--background-color) !important;
    }
  }

  .seller-profile-btn {
    background: #fff;
    color: var(--background-color) !important;
    border: 1px solid var(--background-color) !important;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background: #f5f5f5;
    }
  }
}
.seller-card a {
  display: flex;
  justify-content: center;
}
